exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-advancefares-index-jsx": () => import("./../../../src/pages/advancefares/index.jsx" /* webpackChunkName: "component---src-pages-advancefares-index-jsx" */),
  "component---src-pages-download-index-jsx": () => import("./../../../src/pages/download/index.jsx" /* webpackChunkName: "component---src-pages-download-index-jsx" */),
  "component---src-pages-how-to-use-index-jsx": () => import("./../../../src/pages/how-to-use/index.jsx" /* webpackChunkName: "component---src-pages-how-to-use-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20230908-01-index-mdx": () => import("./../../../src/templates/app_news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20230908-01/index.mdx" /* webpackChunkName: "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20230908-01-index-mdx" */),
  "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20240109-01-index-mdx": () => import("./../../../src/templates/app_news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20240109-01/index.mdx" /* webpackChunkName: "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20240109-01-index-mdx" */),
  "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20240304-01-index-mdx": () => import("./../../../src/templates/app_news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20240304-01/index.mdx" /* webpackChunkName: "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20240304-01-index-mdx" */),
  "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20240316-01-index-mdx": () => import("./../../../src/templates/app_news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20240316-01/index.mdx" /* webpackChunkName: "component---src-templates-app-news-post-jsx-content-file-path-contents-news-20240316-01-index-mdx" */),
  "component---src-templates-news-post-jsx-content-file-path-contents-news-20230908-01-index-mdx": () => import("./../../../src/templates/news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20230908-01/index.mdx" /* webpackChunkName: "component---src-templates-news-post-jsx-content-file-path-contents-news-20230908-01-index-mdx" */),
  "component---src-templates-news-post-jsx-content-file-path-contents-news-20240109-01-index-mdx": () => import("./../../../src/templates/news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20240109-01/index.mdx" /* webpackChunkName: "component---src-templates-news-post-jsx-content-file-path-contents-news-20240109-01-index-mdx" */),
  "component---src-templates-news-post-jsx-content-file-path-contents-news-20240304-01-index-mdx": () => import("./../../../src/templates/news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20240304-01/index.mdx" /* webpackChunkName: "component---src-templates-news-post-jsx-content-file-path-contents-news-20240304-01-index-mdx" */),
  "component---src-templates-news-post-jsx-content-file-path-contents-news-20240316-01-index-mdx": () => import("./../../../src/templates/news-post.jsx?__contentFilePath=/root/work/sma-taxi-cms/contents/news/20240316-01/index.mdx" /* webpackChunkName: "component---src-templates-news-post-jsx-content-file-path-contents-news-20240316-01-index-mdx" */)
}

